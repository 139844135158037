import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AccountAccessStatus, AccountUserRole, AuthService, ExperiencePrivilege, License, LicenseType, WorkroomPrivilege } from '@celum/authentication';
import { DataUtil, tapAllResponses } from '@celum/core';
import { AccountAccess, AccountMember, UserGroup } from '@celum/sacc/domain';
import {
  AccountMemberChanges,
  AccountMemberResourceService,
  AppState,
  NotificationService,
  selectUserLicenseByActiveAccountIdAndLicenseType
} from '@celum/sacc/shared';
import { ErrorService } from '@celum/shared/util';

import { EditAccountMemberDialogComponent } from './edit-account-member-dialog.component';
import { AccountMemberStatusSwitcherConfig } from '../account-member-status-switcher/account-member-status-switcher.component';

export type EditAccountMemberDialogState = {
  editedMember?: any; // This has to be any - using AccountMember for some unknown reason leads to a type-mismatch with the AvatarComponent
  currentUser?: AccountMember;
  token?: string;
  tokenExpiration?: number;
  userGroup?: UserGroup;
  isReadonly?: boolean;
  addedGroups: UserGroup[];
  removedGroups: UserGroup[];
  accountAccess?: AccountAccess;
  isRefreshingToken?: boolean;
  currentStatus?: AccountAccessStatus;
  isPopupOpen?: boolean;
};

export type EditAccountMemberDialogViewModel = {
  hasExperienceAccess: boolean;
  hasWorkroomLicense: boolean;
  hasExperienceLicense: boolean;
  isEditMode: boolean;
  headerTextKey: string;
  isUserDeactivated: boolean;
  hasDriveLicense: boolean;
  isSelf: boolean;
  isEditingOwner: boolean;
  statusSwitcherConfig: AccountMemberStatusSwitcherConfig;
} & Omit<EditAccountMemberDialogState, 'userGroup'>;

export interface EditAccountMemberFormValue {
  status?: AccountAccessStatus;
  role?: AccountUserRole;
  experience?: boolean;
  workroomPrivilege?: WorkroomPrivilege;
  experiencePrivilege?: ExperiencePrivilege;
}

@Injectable()
export class EditAccountMemberDialogService extends ComponentStore<EditAccountMemberDialogState> {
  public vm$ = this.select(
    this.state$,
    this.store$.select(selectUserLicenseByActiveAccountIdAndLicenseType(LicenseType.WORK)).pipe(map((license: License) => license && license.active)),
    this.store$.select(selectUserLicenseByActiveAccountIdAndLicenseType(LicenseType.EXPERIENCE)).pipe(map((license: License) => license && license.active)),
    this.store$.select(selectUserLicenseByActiveAccountIdAndLicenseType(LicenseType.DRIVE)).pipe(map((license: License) => license && license.active)),
    (state, hasWorkroomLicense, hasExperienceLicense, hasDriveLicense) => this.createViewModel(state, hasWorkroomLicense, hasExperienceLicense, hasDriveLicense)
  );

  public updateAccountMember = this.effect<AccountMemberChanges>(update =>
    update.pipe(
      switchMap(changes =>
        this.accountMemberResourceService.updateAccountMember(this.get().editedMember.accountId, this.get().editedMember.id, changes).pipe(map(() => changes))
      ),
      tapAllResponses(
        changes => {
          if (changes.status) {
            const messageKey =
              changes.status === AccountAccessStatus.INACTIVE
                ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.SNACKBAR.SUCCESS_DEACTIVATE'
                : 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.SNACKBAR.SUCCESS_ACTIVATE';
            this.notificationService.info(messageKey);
          }

          this.dialogRef.close(true);
        },
        error => {
          const errors = this.extractErrorsFromResponse(error as HttpErrorResponse);
          if (!DataUtil.isEmpty(errors)) {
            errors.forEach(errorKey => this.errorService.error('EditAccountMemberDialogService', errorKey, error));
          } else {
            this.errorService.httpError(error as HttpErrorResponse, 'EditAccountMemberDialogService');
          }

          this.dialogRef.close(false);
        }
      )
    )
  );

  constructor(
    private authService: AuthService,
    private store$: Store<AppState>,
    private accountMemberResourceService: AccountMemberResourceService,
    protected dialogRef: MatDialogRef<EditAccountMemberDialogComponent>,
    private errorService: ErrorService,
    private notificationService: NotificationService
  ) {
    super({
      addedGroups: [],
      removedGroups: []
    });

    this.select(state => state)
      .pipe(
        filter(state => !state.isRefreshingToken && (!state.token || state.tokenExpiration === undefined || Date.now() > state.tokenExpiration)),
        switchMap(() => {
          this.patchState({ isRefreshingToken: true });
          return this.authService.getAuthResult().pipe(
            tap(result => {
              this.patchState({ tokenExpiration: result.expiresOn.getTime(), token: result.idToken, isRefreshingToken: false });
            })
          );
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  public init(editedMember: AccountMember, accountAccess: AccountAccess, currentUser: AccountMember): void {
    this.patchState({ editedMember: { ...editedMember }, accountAccess, currentUser, currentStatus: editedMember.status });
  }

  public getChangedValues(formValue: EditAccountMemberFormValue, initialEditedMember: AccountMember): AccountMemberChanges {
    const changedValues: Partial<AccountMemberChanges> = {};

    if (formValue.status !== initialEditedMember.status) {
      changedValues.status = formValue.status;
    }

    const addedGroups = this.get().addedGroups;
    if (!DataUtil.isEmpty(addedGroups)) {
      changedValues.groupIdsToAdd = addedGroups.map(group => group.id);
    }

    const removedGroups = this.get().removedGroups;
    if (!DataUtil.isEmpty(removedGroups)) {
      changedValues.groupIdsToRemove = removedGroups.map(group => group.id);
    }

    // For inactivate users all other controls are disabled, and the form doesn't contain any other controls
    if (initialEditedMember.status !== AccountAccessStatus.INACTIVE) {
      if (formValue.role !== initialEditedMember.role) {
        changedValues.role = formValue.role;
      }

      if (formValue.workroomPrivilege !== initialEditedMember.privileges.work) {
        changedValues.privileges = { ...changedValues.privileges, work: formValue.workroomPrivilege };
      }

      if (formValue.experiencePrivilege !== initialEditedMember.privileges.experience) {
        changedValues.privileges = { ...changedValues.privileges, experience: formValue.experiencePrivilege };
      }
    }

    return changedValues;
  }

  private createViewModel(
    state: EditAccountMemberDialogState,
    hasWorkroomLicense: boolean,
    hasExperienceLicense: boolean,
    hasDriveLicense: boolean
  ): EditAccountMemberDialogViewModel {
    const isEditMode = !!state.userGroup && !state.isReadonly;
    const isSelf = state.currentUser.email === state.editedMember.email;
    const isEditingOwner = state.editedMember.email === state.accountAccess.ownerEmail;

    return {
      ...state,
      hasExperienceAccess: (state.editedMember as AccountMember).privileges.experience === ExperiencePrivilege.FULL_ACCESS,
      hasWorkroomLicense,
      isEditMode,
      headerTextKey: state.isReadonly
        ? 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.HEADER.READONLY'
        : isEditMode
          ? 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.HEADER.EDIT'
          : 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.HEADER.CREATE',
      isUserDeactivated: state.editedMember.status === AccountAccessStatus.INACTIVE,
      hasDriveLicense,
      hasExperienceLicense,
      isSelf,
      isEditingOwner,
      statusSwitcherConfig: EditAccountMemberDialogService.getStatusSwitcherConfig(state, isSelf, isEditingOwner, state.editedMember.deactivatedInFederation)
    };
  }

  private extractErrorsFromResponse(error: HttpErrorResponse): string[] {
    return error?.error?.errors.map((errorElement: { errorKey: string }) => errorElement.errorKey);
  }

  private static getStatusSwitcherConfig(
    state: EditAccountMemberDialogState,
    isSelf: boolean,
    isEditingOwner: boolean,
    isDeactivatedInFederation?: boolean
  ): AccountMemberStatusSwitcherConfig {
    const config = {
      memberOrInvitation: state.editedMember,
      valueNotYetChanged: state.editedMember.status === state.currentStatus,
      isInvitation: false
    } as AccountMemberStatusSwitcherConfig;

    if (state.editedMember.status === AccountAccessStatus.ACTIVE && state.currentStatus === AccountAccessStatus.ACTIVE) {
      config.disabled = isSelf || isEditingOwner;
      config.buttonPrimaryIcon = 'user-deactivate';
      config.buttonPrimaryLabel = 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.DEACTIVATE';
      config.buttonPrimaryTooltip = isSelf
        ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.TOOLTIPS.CANNOT_EDIT_IF_OWNER'
        : isEditingOwner
          ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.TOOLTIPS.CANNOT_EDIT_OWNER'
          : '';
    } else if (state.editedMember.status === AccountAccessStatus.INACTIVE) {
      config.disabled = isSelf || isDeactivatedInFederation;
      config.buttonPrimaryIcon = 'user-activate';
      config.buttonPrimaryLabel = 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.ACTIVATE';
      config.buttonPrimaryTooltip = isSelf
        ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.TOOLTIPS.CANNOT_EDIT_IF_OWNER'
        : isEditingOwner
          ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.TOOLTIPS.CANNOT_EDIT_OWNER'
          : isDeactivatedInFederation
            ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.TOOLTIPS.CANNOT_EDIT_PROVISIONED'
            : '';
    }

    if (state.editedMember.status === AccountAccessStatus.INACTIVE && state.currentStatus === AccountAccessStatus.ACTIVE) {
      config.switchedPrimaryTextHeader = 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.ACTIVATE_INFO_HEADER';
      config.switchedPrimaryText = 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.ACTIVATE_INFO_TEXT';
    }

    if (state.editedMember.status === AccountAccessStatus.ACTIVE && state.currentStatus === AccountAccessStatus.INACTIVE) {
      config.switchedPrimaryTextHeader = 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.DEACTIVATE_INFO_HEADER';
      config.switchedPrimaryText = 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.DEACTIVATE_INFO_TEXT';
    }

    return config;
  }
}
